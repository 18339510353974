.ErrorModalTitle {
  font-weight: bold;
  text-align: left;
  margin: 4px 0px;
}

.ErrorModalBody {
  text-align: left;
  margin-bottom: 20px;
}

.ErrorModalButton {
  width: 75%;
  padding: 8px 32px;
  margin-bottom: 8px;
  align-self: center;
  box-sizing: border-box;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  border: 0px solid;
  border-radius: 0px;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
}

.ErrorModalButton {
  background-color: var(--secondary-color);
}

.ErrorModalButton:hover {
  background-color: var(--secondary-color-lighter);
}
