.CloseModalTitle {
  font-weight: bold;
  text-align: left;
  margin: 4px 0px;
}

.CloseModalSubtitle {
  text-align: left;
  margin: 20px 0px 0px 0px;
}

.CloseModalTimer {
  font-weight: bold;
}

.CloseModalBody {
  text-align: left;
  margin-bottom: 20px;
}

.CloseModal button {
  width: 75%;
  margin-bottom: 8px;
  align-self: center;
}

.CloseModalCancelButton {
  background-color: var(--secondary-color);
}

.CloseModalCancelButton:hover {
  background-color: var(--secondary-color-lighter);
}

.CloseModalYesButton {
  background-color: var(--primary-color);
}

.CloseModalYesButton:hover {
  background-color: var(--primary-color-lighter);
}
