.MobileSpeakButton {
  position: absolute;

  width: 84px;
  height: 84px;
  margin: 0 auto;

  border-radius: 42px;
  border: solid var(--mobile-record-start-color) 1px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileSpeakButtonInner {
  background-color: var(--mobile-record-start-color);
  width: 70px;
  height: 70px;
  border-radius: 35px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileSpeakButtonInner:active {
  background-color: var(--mobile-record-tap-color);
}

.MobileSpeakButtonInner.recording {
  animation: pulse 1s 0.65s ease-in-out infinite forwards;
  background-color: var(--mobile-record-stop-color);
}

.MobileSpeakButton.recording {
  animation: pulse 3s 2s ease-in-out infinite forwards;
  border: solid var(--mobile-record-stop-color) 1px;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}
