.UneeqVideo {
  height: 100vh;
  width: 100vw;
  /* TODO: Find a more elegant way to place the uneeq video container below the other elements. */
  z-index: var(--z-index-digitalhuman);
  position: absolute;
}

.UneeqVideoMobile {
  position: absolute;
  z-index: var(--z-index-digitalhuman);
  margin: 8% 0% 0% 0%;
  height: 87%;
}

.UneeqVideoHidden {
  display: none;
}
