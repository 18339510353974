.MobileBottomBar {
  width: 100%;
  height: 107px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--mobile-bars-color);

  transition: opacity 1s;
}

.MobileBottomBar.hidden {
  opacity: 0;
}

.MobileBottomStroke {
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 82px;

  background-color: var(--mobile-bars-background);
}

.BottomCurve {
  position: absolute;
  fill: var(--mobile-bars-background);
}
