.ButtonsComponent {
  width: calc(100% - 10px);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  transition: opacity 1s;
}

.ButtonsComponent.hidden {
  opacity: 0;
}

.ButtonsComponent button {
  padding: 8px 32px;
  margin-top: 3px;
  align-self: center;
  text-align: center;
  background-color: var(--secondary-color);
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  width: 100%;
  border: 0px solid;
  border-radius: 0px;
  font-weight: 500;
  display: inline-block;

  cursor: pointer;
}

.ButtonsComponent button:hover {
  background-color: var(--secondary-color-lighter);
}
