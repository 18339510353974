.PrivacyModalTitle {
  font-weight: bold;
  text-align: left;
  margin: 24px 0px;
}

.PrivacyModalBody {
  text-align: left;
  margin-bottom: 16px;
  width: 100%;
}

.PrivacyModalSubText {
  text-align: left;
  margin-bottom: 20px;
}

.PrivacyModalSubText a {
  text-decoration: underline;
  color: currentColor;
}

.PrivacyModalButton {
  background-color: var(--primary-color);
}

.PrivacyModalButton:hover {
  background-color: var(--primary-color-lighter);
}
