.LogoComponent {
  background-color: rgba(255, 255, 255, 1);
  border-top-left-radius: 20px;
  display: flex;
  padding: 1.5rem 3rem;
  max-width: 200px;
}

.LogoComponent img {
  box-sizing: border-box;
  width: 100%;
  max-height: 100px;
}

@media (max-width: 767px) {
  .LogoComponent {
    padding: 0.75rem 1.5rem;
  }

  .LogoComponent img {
    width: 100px;
    max-height: 50px;
  }
}
