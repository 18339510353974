.MobileContactModalTitle {
  font-weight: bold;
  text-align: left;
  margin: 6px 0px 0px 0px;
}

.MobileContactModalBody {
  text-align: left;
  margin: 20px 0px 0px 0px;
  width: 100%;
}

.MobileContactModalButton {
  margin: 20px 0px 0px 0px;
  align-self: center;
  background-color: var(--primary-color);
}

.MobileContactModalButton:hover {
  background-color: var(--primary-color-lighter);
}
