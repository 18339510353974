.LandingPage {
  padding: 3rem;
  max-width: 800px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ImageComponent {
  width: 100%;
}

@media (max-width: 767px) {
  .LandingPage {
    padding: 1rem;
    width: 100%;
    max-width: 100%;
  }
}
