.TitleComponent {
  align-self: flex-start;
}

.TitleComponent p {
  color: var(--text-dark-grey);
  text-align: left;
  font-size: 45px;
  margin: 0.5rem 0;
  line-height: 1;
}
