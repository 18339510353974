.StartButton {
  align-self: flex-start;
  margin: 1rem 0 0 0;
  padding: 0px 15px;
  max-width: 200px;
  height: 40px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.StartButton span {
  color: white;
  text-align: center;
}

.StartButton:hover {
  background-color: var(--primary-color-lighter);
}
