.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  background-color: var(--background-color-modal);
  height: auto;
  width: 90%;
  max-width: 500px;
  overflow: hidden;
  padding: 24px;
}

.Modal button {
  padding: 8px 32px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  border: 0px solid;
  border-radius: 0px;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
}
