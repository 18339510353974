.MobileMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: lightgray;
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0%);
  z-index: var(--z-index-mobilemenu);
}

.MobileMenu-visible {
  transform: translateX(-100%);
}

.MobileMenu button {
  border: 0;
  padding: 0;
  background: transparent;
  font-size: 2rem;
  text-transform: uppercase;
  padding: 2rem 0;
  font-weight: bold;
  letter-spacing: 0.5rem;
  color: var(--text-dark-grey);
  text-decoration: none;
  transition: color 0.3s linear;
}

@media (max-width: 767px) {
  .MobileMenu {
    width: 100%;
  }

  .MobileMenu button {
    font-size: 1.5rem;
    text-align: center;
  }
}
