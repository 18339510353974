.SpaceBarTutorialWrapper {
  position: absolute;
  bottom: 162px;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.SpaceBarTutorial {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.5) 83%, rgba(0, 0, 0, 0) 100%);
  width: 45%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 24px;

  animation: bgAnimation 1s forwards;

  transition: opacity 1s;
}

.SpaceBarTutorial.hidden {
  opacity: 0;
}

.SpaceBarTutorialTitle {
  font-size: 16px;
  color: white;

  opacity: 0;
  filter: blur(8px);
  animation: removeblur 1s 0.3s forwards;
}

.SpaceBarTutorialBody {
  color: white;
  white-space: pre-line;
  font-size: 20px;
  margin: 0px 0px 16px;

  opacity: 0;
  filter: blur(8px);
  animation: removeblur 1s 0.3s forwards;
}

.SpaceBarTutorialImages {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;

  max-width: 25%;
}

.CommandKeyIcon {
  opacity: 0;
  margin: 0px 8px 0px 0px;
  animation: toFullOpacity 1s 0.3s forwards;
}

.SpaceBarIcon {
  opacity: 0;
  margin: 0px 8px 0px 0px;
  animation: toFullOpacity 1s 0.3s forwards, pulse 2s 1.3s ease-in-out infinite forwards;
}

@keyframes toFullOpacity {
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bgAnimation {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 34% 100%;
  }
}

@keyframes removeblur {
  to {
    filter: blur(0px);
    -webkit-filter: blur(0px);
    opacity: 1;
  }
}
