.Spinner {
  width: 100px;
  height: 100px;

  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  position: absolute;
  margin: 100px auto;
}

.SpinnerDoubleBounce1,
.SpinnerDoubleBounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--spinner-color);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.SpinnerDoubleBounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
