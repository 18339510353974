.containerMain {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.firstRow > div {
  width: 33%;
  max-width: 300px;
}

.inputRow {
  width: 100%;
  margin: auto auto 1vh auto;
  position: relative;
}

.inputRowMobile {
  width: 100%;
  margin: auto auto 1vh auto;
  position: relative;
}

.lastRow {
  display: flex;
  justify-content: space-between;
}
