.MobileSuggestedQuestion {
  background: var(--mobile-actions-background);
  box-shadow: 0 2px 7px 2px var(--mobile-actions-shadow);
  border-radius: 22.5px;
  margin: 5px 10px;
  padding: 10px 0px;
  color: var(--text-dark-grey);
  text-align: right;
}

.MobileSuggestedQuestion.pulse {
  animation: pulse 2s 1.3s ease-in-out infinite forwards;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.MobileSuggestedQuestion span {
  font-size: 16px;
  margin: 0px 15px;
}

.MobileSuggestedQuestion:active {
  background: var(--mobile-actions-background-active);
}
