.ContactBoxWrapper {
}

.ContactBox {
  margin: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  white-space: pre-line;
  padding: 2rem;
  transition: opacity 1s;
}

.ContactBox.hidden {
  opacity: 0;
}
.ContactBox span {
  color: rgba(0, 123, 133, 1);
  text-align: center;
  font-size: 0.75rem;
}
