.MobileBurger {
  position: absolute;
  top: 2.5%;
  left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: var(--z-index-mobileburger);
}

.MobileBurger:focus {
  outline: none;
}

.MobileBurger div {
  width: 1.5rem;
  height: 0.18rem;
  border-radius: 8px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.MobileBurgerFirst-closed {
  background-color: var(--mobile-bars-color);
  transform: rotate(0);
}

.MobileBurgerFirst-open {
  background-color: var(--mobile-bars-background);
  transform: rotate(45deg);
}

.MobileBurgerSecond-closed {
  background-color: var(--mobile-bars-color);
  opacity: 1;
  transform: translateX(0%);
}

.MobileBurgerSecond-open {
  opacity: 0;
  transform: translateX(20px);
}

.MobileBurgerLast-closed {
  background-color: var(--mobile-bars-color);
  transform: rotate(0);
}

.MobileBurgerLast-open {
  background-color: var(--mobile-bars-background);
  transform: rotate(-45deg);
}
