.SuggestedLink {
  min-height: 50px;
  width: 100%;
  margin-top: 3px;
  background-color: var(--secondary-color);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SuggestedLink span {
  margin: 10px 5px 10px 25px;
}

.SuggestedLink img {
  box-sizing: border-box;
  height: 30px;
  margin: 10px 15px 10px 5px;
}
.SuggestedLink .icon {
  margin-right: 20px;
}

.SuggestedLink.pulse {
  animation: pulse 2s 1.3s ease-in-out infinite forwards;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
