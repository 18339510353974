.PrivacyComponentWrapper {
  display: flex;
  align-items: flex-end;
  margin: 0 0 0 10px;
}

.PrivacyComponent p {
  color: white;
}

.PrivacyComponent span {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 767px) {
  .PrivacyComponent p {
    font-size: 10px;
  }
}
