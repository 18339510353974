.MobileTopBar {
  width: 100%;
  height: 65px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--mobile-bars-background);
  transition: opacity 1s;

  border-bottom: 1px solid var(--mobile-bars-top-border);
}

.MobileTopBar.hidden {
  opacity: 0;
}
