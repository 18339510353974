.SettingsModal {
  overflow: visible;
}

.SettingsModalTitle {
  font-weight: bold;
  text-align: left;
  margin: 24px 0px;
}

.SettingsModalBody {
  text-align: left;
  margin-bottom: 16px;
  width: 100%;
}

.SettingsModalSubText {
  text-align: left;
  margin-bottom: 20px;
}

.SettingsModalSubText a {
  text-decoration: underline;
  color: currentColor;
}
.SettingsModalButtonContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.SettingsButtonSave {
  background-color: var(--primary-color);
}

.SettingsButtonSave:hover {
  background-color: var(--primary-color-lighter);
}

.SettingsButtonClose {
  background-color: var(--secondary-color);
}

.SettingsButtonClose:hover {
  background-color: var(--secondary-color-lighter);
}

.SettingsModalSelect {
  margin-top: 10px;
}
