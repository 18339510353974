.LoadingComponent {
  position: absolute;
  height: 100%;
  width: 100%;

  z-index: var(--z-index-loadingcomponent);
  transition: opacity 1s;
}

.LoadingComponent.hidden {
  opacity: 0;
}

.LoadingBackground {
  height: 100%;
  width: 100%;

  filter: blur(8px);
  -webkit-filter: blur(8px);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  transform: scale(1.03);
  position: fixed;
}
