.LandingPageLogos {
  overflow: hidden;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.LandingPageLogos img {
  margin: 15px 20px 0px 20px;
  max-width: calc(100% / 4);
  max-height: 80px;
}

@media (max-width: 767px) {
  .LandingPageLogos img {
    margin: 15px 10px 0px 10px;
  }
}
