.PrivacyDescription {
  white-space: pre-line;
}

.PrivacyDescription p {
  margin: 10px 0px;
  color: var(--text-dark-grey);
  font-size: 13px;
  text-align: left;
}

.PrivacyDescription span {
  text-decoration: underline;
  cursor: pointer;
}
